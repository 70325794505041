import ApolloClient from 'apollo-boost';
import fetch from 'isomorphic-fetch';

export const client = new ApolloClient({
  uri: process.env.SPORTLINK_API_URL,
  fetch,
  // headers: {
  //   'Content-Type': 'application/json',
  // },
  fetchOptions: {
    // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
    // @ts-ignore: typings are wrong
    mode: 'cors',
  },
});
