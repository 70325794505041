// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-post-tsx": () => import("../src/templates/post.tsx" /* webpackChunkName: "component---src-templates-post-tsx" */),
  "component---src-templates-page-tsx": () => import("../src/templates/page.tsx" /* webpackChunkName: "component---src-templates-page-tsx" */),
  "component---src-templates-team-tsx": () => import("../src/templates/team.tsx" /* webpackChunkName: "component---src-templates-team-tsx" */),
  "component---src-pages-404-tsx": () => import("../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-agenda-tsx": () => import("../src/pages/agenda.tsx" /* webpackChunkName: "component---src-pages-agenda-tsx" */),
  "component---src-pages-contact-tsx": () => import("../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-foto-albums-tsx": () => import("../src/pages/foto-albums.tsx" /* webpackChunkName: "component---src-pages-foto-albums-tsx" */),
  "component---src-pages-index-tsx": () => import("../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-nieuws-tsx": () => import("../src/pages/nieuws.tsx" /* webpackChunkName: "component---src-pages-nieuws-tsx" */),
  "component---src-pages-over-csv-tsx": () => import("../src/pages/over-csv.tsx" /* webpackChunkName: "component---src-pages-over-csv-tsx" */),
  "component---src-pages-schooltoernooi-tsx": () => import("../src/pages/schooltoernooi.tsx" /* webpackChunkName: "component---src-pages-schooltoernooi-tsx" */),
  "component---src-pages-sponsors-tsx": () => import("../src/pages/sponsors.tsx" /* webpackChunkName: "component---src-pages-sponsors-tsx" */),
  "component---src-pages-teams-tsx": () => import("../src/pages/teams.tsx" /* webpackChunkName: "component---src-pages-teams-tsx" */),
  "component---src-pages-trainingstijden-tsx": () => import("../src/pages/trainingstijden.tsx" /* webpackChunkName: "component---src-pages-trainingstijden-tsx" */)
}

